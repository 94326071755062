import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IEntity } from '../../../interfaces/entity.interface';
import { ReferenceLookupService } from '../../../services/reference-lookup-service.service';
import { ITableOptions } from '../../../../../shared/interfaces/table/table-options.interface';

@Component({
  selector: 'suvo-bi-reference-autocomplete-selector',
  templateUrl: './reference-autocomplete-selector.component.html',
  styleUrls: ['./reference-autocomplete-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReferenceAutocompleteSelectorComponent),
      multi: true,
    },
  ],
})
export class ReferenceAutocompleteSelectorComponent implements ControlValueAccessor {
  separatorKeyCodes: number[] = [ENTER, COMMA];

  @Input() disabled = false;
  @Input() simpleFilters: any;
  @Input() filters: any;
  @Input() pageSize: number = 10;

  allowMultiple = false;

  @ViewChild('entityInput') entityInput: ElementRef<HTMLInputElement>;
  @ViewChild('entityAutocomplete') entityAutocomplet: MatAutocomplete;
  entityInputControl: FormControl;

  chippableOptions: { key: string; primary: string; accent: string }[];

  entityOptions: IEntity[] = [];
  entityOptionsUnloaded = true;
  entityOptionsReceived = new Subject<IEntity[]>();

  selectedEntities: IEntity[] = [];
  unselectedEntities: IEntity[] = [];

  filteredEntitiesSubject = new Subject<IEntity[]>();

  entityDefinition;
  subjectDefinition;

  unsubscribe$ = new Subject<boolean>();

  formGroup?: FormGroup;

  @Input() entityDefinitionAlias: string;
  @Input() referenceLabelProperty: string;

  referenceChanges = new Subject();

  loadingEntities = false;

  fetchSequenceNumber = 0;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private referenceLookupService: ReferenceLookupService,
  ) {}

  async ngOnInit() {
    this.entityInputControl = new FormControl();

    this.entityInputControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(100))
      .subscribe((text) => this.filterEntityOptions(text));

    this.entityDefinition = await this.referenceLookupService.getDefinition(
      this.entityDefinitionAlias,
    );
    this.subjectDefinition = await this.referenceLookupService.getDefinition(
      this.entityDefinitionAlias,
    );

    this.generateChippableOptions();
  }

  ngOnChanges() {
    this.entityInputControl?.[this.disabled ? 'disable' : 'enable']();
  }

  async writeValue(obj: any) {
    if (obj) {
      let entity = await this.referenceLookupService.getOne(this.entityDefinitionAlias, obj);

      if (entity) {
        let foundSelectedEntity = this.selectedEntities.find((e) => {
          return e?.data?.name == entity?.data?.name;
        });

        if (!foundSelectedEntity) {
          this.addEntity(entity, true);
        }
      }
    }
  }

  registerOnChange(fn: any): void {
    this.referenceChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.referenceChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  generateChippableOptions() {
    const propertyName = this.referenceLabelProperty;
    if (!propertyName) {
      return;
    }
    const propertyDefinition = this.subjectDefinition?.properties?.find(
      (p) => p.name === propertyName,
    );
    if (!propertyDefinition) {
      return;
    }

    const chippableOptions = propertyDefinition.displayOptions?.enumColourSet;
    this.chippableOptions = chippableOptions;

    this.changeDetectorRef.markForCheck();
  }

  filterEntityOptions(text: string | null): void {
    this.fetchOptions(text);
  }

  async fetchEntityOptions(search: string): Promise<void> {
    this.fetchSequenceNumber++;
    let currentFetchSequenceNumber = this.fetchSequenceNumber;

    let options: ITableOptions = {
      pagination: {
        pageIndex: 0,
        pageSize: this.pageSize,
      },
      search,
      simpleFilters: this.simpleFilters,
      filters: this.filters,
    };

    this.loadingEntities = true;

    let entities = await this.referenceLookupService.getPaginated(
      this.entityDefinitionAlias,
      options,
      '',
    );

    if(currentFetchSequenceNumber != this.fetchSequenceNumber){
      //Abort if there has already been a new fetch requested
      return;
    }

    this.loadingEntities = false;

    this.entityOptions = [];

    entities?.data.forEach((entity: any) => {
      this.entityOptions.push(entity as IEntity);
    });

    this.unselectedEntities = [...this.entityOptions];
    this.entityOptionsReceived.next(this.entityOptions);
    this.filteredEntitiesSubject.next(this.entityOptions);
  }

  /**
   * Input focussed
   */

  async fetchOptions(search: string): Promise<void> {
    this.entityOptionsUnloaded = false;
    await this.fetchEntityOptions(search);
  }

  async onInputFocus(): Promise<void> {
    this.fetchOptions(null);
  }

  /**
   * Entity Adding and Removing
   */

  assignEntityChipColor(entity: IEntity): void {
    if (entity.chipColor) {
      return;
    }

    const chipColors = this.chippableOptions?.find((colors) => colors.key === entity.data.name);

    entity.chipColor = chipColors?.primary || '#1a78cf';
    entity.chipAccent = chipColors?.accent || '#fff';
  }

  addEntity(entity: IEntity, dontEmitChanges?: boolean): void {
    const index = this.unselectedEntities.indexOf(entity);

    if (index >= 0) {
      this.unselectedEntities.splice(index, 1);
    }

    this.assignEntityChipColor(entity);
    this.selectedEntities.push(entity);

    this.filteredEntitiesSubject.next(this.unselectedEntities);

    if (!dontEmitChanges) {
      this.emitPropertyChanges();
    }
  }

  autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
    this.entityInput.nativeElement.value = '';
    this.entityInputControl.setValue(null);

    const entity: IEntity = event.option.value;
    this.addEntity(entity);

    this.entityInput.nativeElement.focus();
  }

  removeEntity(entity: IEntity, dontEmitChanges: boolean = false): void {
    const index = this.selectedEntities.indexOf(entity);
    if ((index) >= 0) {
      this.unselectedEntities.push(entity);
      this.selectedEntities.splice(index, 1);

      this.filteredEntitiesSubject.next(this.unselectedEntities);

      if (!dontEmitChanges) {
        this.emitPropertyChanges();
      }
    }
  }

  getEntityLabel(entity: IEntity) {
    if (this.entityDefinition.referenceLabelPropertyKey) {
      if (this.entityDefinition.referenceLabelPropertyKey.split('.').length == 2) {
        let keyParts = this.entityDefinition.referenceLabelPropertyKey.split('.');

        return entity[keyParts[0]][keyParts[1]];
      } else {
        return entity[this.entityDefinition.referenceLabelPropertyKey];
      }
    } else {
      return entity?.data?.name;
    }
  }

  emitPropertyChanges() {
    this.referenceChanges.next(this.selectedEntities.map((e) => e._id));
  }

  clear() {
    this.selectedEntities = [];

    this.filteredEntitiesSubject.next(this.unselectedEntities);

    this.emitPropertyChanges();
  }
}
