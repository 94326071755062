<div
  *ngIf="entityInputControl"
  class="reference-selector-container"
  fxLayout="column"
  fxLayoutAlign="flex-start stretch"
>
  <mat-form-field #formField class="full-width" appearance="outline">
    <mat-label>{{ entityDefinition?.name || 'Reference' }}</mat-label>

    <!-- Chips -->
    <mat-chip-grid #entityChipList [disabled]="disabled">
      <mat-basic-chip
        *ngFor="let entity of selectedEntities"
        color="primary"
        class="chip"
        [ngStyle]="{ 'background-color': entity.chipColor, color: entity.chipAccent }"
        (removed)="removeEntity(entity)"
      >
        {{ getEntityLabel(entity) }}
      </mat-basic-chip>

      <!-- Input -->
      <input
        #entityInput
        [placeholder]="disabled ? '' : 'Search...'"
        [formControl]="entityInputControl"
        [hidden]="disabled"
        [matAutocomplete]="entityAutocomplete"
        [matChipInputFor]="entityChipList"
        (focus)="onInputFocus()"
        *ngIf="allowMultiple || selectedEntities.length == 0"
      />
    </mat-chip-grid>

    <!-- Auto Complete -->
    <mat-autocomplete
      autoActiveFirstOption
      #entityAutocomplete="matAutocomplete"
      (optionSelected)="autocompleteSelected($event)"
    >
      <mat-option *ngFor="let entity of filteredEntitiesSubject | async" [value]="entity" [class]="{'loadingEntities':loadingEntities}">
        {{ getEntityLabel(entity) }}
      </mat-option>
      <mat-option *ngIf="loadingEntities">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- <p>{{ option.filterProperties | json }}</p> -->

  <button
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="$event.stopPropagation(); clear()"
    *ngIf="selectedEntities.length && !disabled"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
